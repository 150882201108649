.Footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 12vw;
  position: absolute;
  bottom: 0;
}

.FooterContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  z-index: 5;
  background-color: $color-claro;
}

.FooterMenu {
  width: 70%;
  margin: 0 auto;
  &__List {
    list-style: none;
    padding: 0;
    &--Links {
      font-size: 7vw;
      font-weight: 500;
      margin: 5vw 0;
      text-decoration: none;
      font-family: $Fuente3;
    }
  }
}

.FooterMenuOptions {
  transition: 0.3s;
  will-change: color;
  color: $color-variacion7;
  &:hover {
    color: $color-variacion8;
  }
}

.FooterSocialMedia {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7vw auto;
  &__Links {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8vw;
    height: 8vw;
    margin: 0 5vw;
    &--Image {
      width: 100%;
    }
  }
}

.FooterSocialMedia__Links--Image.fb {
  width: 7vw;
  height: 7vw;
}

.Footer-Logo {
  width: 45vw;
  margin: 15vw auto 10vw;
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1100px) {
  .Footer {
    padding: 2vw 0;
  }

  .FooterContainer {
    width: 75%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  .FooterMenu {
    width: 20%;

    margin: 0;
    &__List {
      &--Links {
        font-size: 1.2vw;
        margin: 0.5vw 0;
      }
    }
  }

  .FooterSocialMedia {
    width: 12vw;

    margin: 0;
    &__Links {
      width: 1.5vw;
      height: 1.5vw;
      margin: 0 1vw;
      &--Image {
        width: 100%;
        transition: 0s;
        will-change: filter;
        &:hover {
          filter: brightness(0) saturate(100%) invert(16%) sepia(12%)
            saturate(3850%) hue-rotate(149deg) brightness(95%) contrast(91%);
        }
      }
    }
  }

  .FooterSocialMedia__Links--Image.fb {
    width: 1.5vw;
    height: 1.5vw;
  }

  .Footer-Logo {
    width: 12%;
    position: absolute;
    margin: 0 44%;
  }
}
