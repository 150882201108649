.Header {
  height: 24vw;
  width: 100%;
  position: absolute;
  z-index: 3;
}

.HeaderContainer {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__MobileSpace {
    width: 14vw;
    height: 14vw;
    display: flex;
  }
  &__Logo {
    width: 30vw;
    padding-top: 3vw;
    &--Image {
      width: 100%;
    }
  }
  &__MenuButton {
    width: 14vw;
    height: 14vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 6;
    &--Line {
      margin: 0;
      position: absolute;
      display: flex;
      width: 70%;
      border-radius: 100vw;
      height: 1vw;
      will-change: margin transform;
      transition: 0.3s;
      background-color: $color-claro;
      border: 1px solid $color-claro;
    }
  }
}

.MenuButtonFixed {
  position: fixed;
  right: 10%;
}

.MenuLine1 {
  margin-bottom: -3vw;

  &__Active {
    transform: rotateZ(45deg);
    margin: 0;
  }
}
.MenuLine2 {
  margin-top: -3vw;
  &__Active {
    transform: rotateZ(-45deg);
    margin: 0;
  }
}

.MenuContainer {
  position: fixed;
  width: 100%;
  height: 100vh;
  min-height: 160vw;
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: 5;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s ease-in;
  background-color: $color-variacion7;
}

.Menu-Logo {
  width: 45vw;
  margin: 10vw auto 5vw;
}

.Menu {
  width: 70%;
  margin: 0 auto;
  &__List {
    list-style: none;
    padding: 0;
    &--Links {
      font-size: 7vw;
      font-weight: 500;
      margin: 3vw 0;
      text-decoration: none;
      font-family: $Fuente3;
    }
  }
}

.MenuOptions {
  color: $color-claro;
}

.MenuOptions.active {
  color: $color-variacion8;
}

.SocialMedia {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6vw auto 0;
  &__Links {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 9vw;
    height: 9vw;
    margin: 0 5vw;
    &--Image {
      width: 100%;
    }
  }
}

.SocialMedia__Links--Image.fb {
  width: 8vw;
  height: 8vw;
}

.MenuIsActive {
  opacity: 1;
  pointer-events: all;
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 1100px) {
  .Header {
    height: 6vw;
    padding-top: 1vw;
  }

  .HeaderContainer {
    justify-content: flex-start;
    width: 80%;
    &__MobileSpace {
      display: none;
    }
    &__Logo {
      width: 7vw;
      z-index: 10;
      padding: 0;
    }
    &__MenuButton {
      display: none;
    }
  }

  .MenuContainer {
    position: relative;
    width: 40%;
    height: 4vw;
    min-height: 4vw;
    overflow: visible;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    z-index: 5;
    margin: 0 0 0 5%;
    top: 0;
    left: 0;
    opacity: 1;
    pointer-events: all;
  }

  .Menu-Logo {
    display: none;
  }

  .Menu {
    width: 100%;
    display: flex;
    align-items: center;
    &__List {
      width: 100%;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0;
      &--Links {
        font-size: 1vw;
        margin: 0;
      }
    }
  }

  .MenuOptions {
    color: $color-claro;
  }

  .MenuOptions p {
    margin: 0;
    transition: 0.3s;
    will-change: color;
  }

  .MenuOptions p:hover {
    color: $color-variacion8;
  }

  .SocialMedia {
    display: none;
  }

  .MenuOptions.active {
    color: $color-variacion8;
  }
}
