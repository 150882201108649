$color-claro: #fff;
$color-primario: #000;
$color-secundario: #aaa;
$color-gris: #d2d4d3;
$color-terciario: #eef0ef;
$color-variacion1: #c3b7b4;
$color-variacion2: #cdbfbc;
$color-variacion3: #817776;
$color-variacion4: #685f5e;
$color-variacion5: #626767;
$color-variacion6: #aa856c;
$color-variacion7: #2a7d90;
$color-variacion8: #0c3944;

$Fuente1: "Arsenal", sans-serif;
$Fuente2: "Montserrat", sans-serif;
$Fuente3: "Spinnaker", sans-serif;
